import React from 'react';
import { WolfiaLogoIcon } from './WolfiaLogoIcon';
import { WolfiaLogoText } from './WolfiaLogoText';
import Link from 'next/link';

export const WolfiaLogo = ({ path = 'https://wolfia.com', width = 32, height = 32 }) => (
  <Link href={path} aria-label="Go to Wolfia homepage" className="flex items-center gap-2 no-underline text-plum-950">
    <WolfiaLogoIcon width={width} height={height} aria-hidden="true" />
    <WolfiaLogoText width={width * 2} height={width * 2} aria-hidden="true" />
  </Link>
);

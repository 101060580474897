import { Button } from "./Button";
import Section from "./Section";
import Heading from "./Heading";
import { redirectToLoginOrDashboard } from "@/utils/url";

export const CTABox = ({ roundedBorder = true, borderColor = "lupine-100", border = true }) => {
  const borderColorClass = `border-${borderColor}`;
  const roundedClass = roundedBorder ? "rounded-3xl" : "";
  const borderClass = border ? "border" : "";

  return (
    <div
      className={`${borderClass} ${borderColorClass} ${roundedClass} bg-clip-border px-8 md:px-16 py-16 md:py-28 flex flex-col items-center gap-8 bg-no-repeat bg-top bg-[length:100%]`}
      style={{ backgroundImage: 'url(/images/logos/logo-stroke.svg)' }}
    >
      <Heading level="h2" align="center">
        No more late-night questionnaire scrambles
      </Heading>
      <div className="flex flex-col sm:flex-row gap-4">
        <Button
          CTA="Signup for free"
          href={redirectToLoginOrDashboard()}
          variant="primary"
          width="auto"
        />
        <Button
          CTA="Get a demo"
          href="https://calendly.com/naren-mano/wolfia-demo"
          width="auto"
        />
      </div>
    </div>
  );
};
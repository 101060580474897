import React from 'react';

interface Props {
  width: number | string;
  height: number | string;
}

export const WolfiaLogoText = ({ width, height }: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 67 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Wordmark">
      <path
        d="M58.5337 16.1105C58.5337 16.4187 58.6062 16.7089 58.7513 16.9809C58.9145 17.2529 59.1411 17.4795 59.4313 17.6609C59.7214 17.8241 60.0659 17.9057 60.4649 17.9057C61.0451 17.9057 61.6073 17.7697 62.1513 17.4977C62.6953 17.2257 63.203 16.8539 63.6745 16.3825V14.0161H60.7097C60.0206 14.0161 59.4857 14.2337 59.1049 14.6689C58.7241 15.1041 58.5337 15.5846 58.5337 16.1105ZM57.4185 10.1265L56.6025 7.62408C56.947 7.38834 57.4094 7.18888 57.9897 7.02567C58.5699 6.84434 59.1593 6.70834 59.7577 6.61768C60.3561 6.50888 60.8366 6.45447 61.1993 6.45447C62.2329 6.45447 63.1667 6.66301 64.0009 7.08008C64.835 7.47901 65.4969 8.04114 65.9865 8.76648C66.4942 9.49181 66.7481 10.2987 66.7481 11.1873V20.0001H64.6809L64.2185 18.6673C63.747 19.1206 63.1849 19.5105 62.5321 19.8369C61.8793 20.1633 61.2083 20.3265 60.5193 20.3265C58.9417 20.3265 57.7721 19.9366 57.0105 19.1569C56.2489 18.3771 55.8681 17.3617 55.8681 16.1105C55.8681 14.7867 56.3214 13.7713 57.2281 13.0641C58.1347 12.3569 59.3134 11.9942 60.7641 11.9761H63.6473V11.8945C63.6291 11.1147 63.4387 10.4801 63.0761 9.99048C62.7134 9.48274 62.1059 9.22888 61.2537 9.22888C60.5646 9.22888 59.8755 9.32861 59.1865 9.52808C58.5155 9.70941 57.9262 9.90888 57.4185 10.1265Z"
        fill="#220E67"
      />
      <path
        d="M53.6502 20.0001H50.5766V6.80808H53.6502V20.0001ZM52.0726 4.68648C51.5649 4.68648 51.1387 4.51421 50.7942 4.16968C50.4678 3.82514 50.3046 3.38994 50.3046 2.86407C50.3046 2.35634 50.4678 1.93021 50.7942 1.58567C51.1387 1.24114 51.5649 1.06887 52.0726 1.06887C52.5622 1.06887 52.9883 1.24114 53.351 1.58567C53.7137 1.93021 53.895 2.35634 53.895 2.86407C53.895 3.37181 53.7137 3.80701 53.351 4.16968C53.0065 4.51421 52.5803 4.68648 52.0726 4.68648Z"
        fill="#220E67"
      />
      <path
        d="M40.4404 9.22888V6.91688H42.4804V5.52968C42.4804 4.58674 42.6617 3.77074 43.0244 3.08167C43.4052 2.39261 43.9401 1.85767 44.6292 1.47687C45.3182 1.09607 46.1524 0.905675 47.1316 0.905675C47.4217 0.905675 47.7118 0.941942 48.002 1.01448C48.3102 1.06887 48.6004 1.15047 48.8724 1.25928C49.1625 1.36808 49.3892 1.48594 49.5524 1.61287L48.7092 3.92488C48.4916 3.81608 48.2558 3.73448 48.002 3.68008C47.7662 3.62567 47.5486 3.59847 47.3492 3.59847C46.7145 3.59847 46.2521 3.81607 45.962 4.25127C45.69 4.66834 45.554 5.22141 45.554 5.91048V6.91688H48.002L48.9812 9.22888H45.554V20.0001H42.4804V9.22888H40.4404Z"
        fill="#220E67"
      />
      <path
        d="M38.7792 20.0001H35.76V1.09607L38.7792 0.796875V20.0001Z"
        fill="#220E67"
      />
      <path
        d="M26.8748 20.3537C25.4785 20.3537 24.2727 20.0635 23.2572 19.4833C22.2417 18.903 21.462 18.0961 20.918 17.0625C20.374 16.0107 20.102 14.7958 20.102 13.4177C20.102 12.0033 20.3831 10.7793 20.9452 9.74568C21.5073 8.71208 22.2961 7.90514 23.3116 7.32487C24.3452 6.74461 25.5511 6.45447 26.9292 6.45447C28.3255 6.45447 29.5223 6.74461 30.5196 7.32487C31.5351 7.90514 32.3239 8.71208 32.886 9.74568C33.4481 10.7793 33.7292 11.9942 33.7292 13.3905C33.7292 14.7686 33.4391 15.9835 32.8588 17.0353C32.2967 18.087 31.5079 18.903 30.4924 19.4833C29.4769 20.0454 28.2711 20.3355 26.8748 20.3537ZM26.902 17.7153C27.6636 17.7153 28.3164 17.5249 28.8604 17.1441C29.4044 16.7633 29.8215 16.2465 30.1116 15.5937C30.4199 14.9409 30.574 14.2065 30.574 13.3905C30.574 12.5563 30.4289 11.8129 30.1388 11.1601C29.8487 10.5073 29.4316 9.99954 28.8876 9.63688C28.3436 9.25608 27.6908 9.06568 26.9292 9.06568C26.2039 9.06568 25.5601 9.25608 24.998 9.63688C24.4359 10.0177 24.0007 10.5345 23.6924 11.1873C23.4023 11.8401 23.2481 12.5745 23.23 13.3905C23.23 14.2246 23.3751 14.9681 23.6652 15.6209C23.9553 16.2737 24.3815 16.7905 24.9436 17.1713C25.5057 17.5339 26.1585 17.7153 26.902 17.7153Z"
        fill="#220E67"
      />
      <path
        d="M15.4512 20.0001H12.8128L10.0928 11.2689L7.50881 20.0001H4.84321L0.872009 7.32487L3.86401 6.31848L6.25761 15.3217L8.48801 6.80808H11.8064L14.2 15.3217L16.4304 6.80808H19.6128L15.4512 20.0001Z"
        fill="#220E67"
      />
    </g>
  </svg>
);

import React from 'react';
import { styled } from '@stitches/react';
import { MarketingFooter, MarketingNav } from "@/components/marketing/Nav";

const PageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: 'white',
  fontFamily: '"General Sans Variable", sans-serif',
});

const MainContent = styled('main', {
  margin: 'auto',
  width: '100%',
  position: 'relative',
  paddingBottom: '8rem',
});

type BackgroundVariant = 'lupine' | 'white';

interface MarketingPageProps {
  children: React.ReactNode;
  background?: BackgroundVariant;
}

export const MarketingPage: React.FC<MarketingPageProps> = ({ 
  children, 
}) => {
  return (
    <>
      <PageContainer>
        <MarketingNav />
        <MainContent>{children}</MainContent>
        <MarketingFooter />
      </PageContainer>
    </>
  );
};
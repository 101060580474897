import React, { FC } from 'react';
import cn from 'classnames';

interface HeadingProps {
  children: React.ReactNode;
  level: 'h1' | 'h2' | 'h3' | 'h4';
  align?: 'left' | 'center' | 'right';
  color?: 'primary' | 'secondary' | 'tertiary' | 'accentPrimary' | 'accentSecondary' | 'accentTertiary' | 'accent' | 'inverted' | 'positive';
  className?: string;
}

const headingSizes = {
  h1: 'text-3xl md:text-6xl',
  h2: 'text-xl md:text-3xl',
  h3: 'text-xl md:text-2xl',
  h4: 'text-xl md:text-xl',
};

const trackingClasses = {
  h1: '-tracking-[0.03em]',
  h2: '-tracking-[0.03em]',
  h3: 'tracking-[0.0025em]',
  h4: 'tracking-[0.0025em]',
};

const alignClasses = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
};

const colorClasses = {
  default: 'text-inherit',
  primary: 'text-silvermist-950',
  secondary: 'text-silvermist-950/60',
  tertiary: 'text-silvermist-950/40',
  accentPrimary: 'text-lupine-950',
  accentSecondary: 'text-lupine-950/60',
  accentTertiary: 'text-lupine-950/40',
  accent: 'text-lupine-600',
  inverted: 'text-white',
  positive: 'text-pineglade-600'
};

const Heading: FC<HeadingProps> = ({ children, level, align, color, className = '' }) => {
  const Tag = level;

  const classes = cn(
    headingSizes[level],
    'font-marketing',
    'font-[570]',
    colorClasses[color],
    trackingClasses[level],
    alignClasses[align],
    className
  );

  return <Tag className={classes}>{children}</Tag>;
};

export default Heading;
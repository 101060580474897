import Link from 'next/link';
import cn from 'classnames';
import React from 'react';

interface ButtonProps {
  CTA: string;
  href: string;
  variant?: "primary" | "default";
  width?: "full" | "auto";
}

// const useMousePosition = () => {
//   const [mousePosition, setMousePosition] = React.useState({ x: null, y: null });
//   React.useEffect(() => {
//     const updateMousePosition = ev => {
//       setMousePosition({ x: ev.clientX, y: ev.clientY });
//     };
//     window.addEventListener('mousemove', updateMousePosition);
//     return () => {
//       window.removeEventListener('mousemove', updateMousePosition);
//     };
//   }, []);
//   return mousePosition;
// };

export const Button: React.FC<ButtonProps> = ({ CTA, href, variant = "default", width ="full" }) => {
  const primaryStyles = "border-lupine-600 bg-gradient-to-b from-[rgba(255,255,255,0.25)] to-[rgba(255,255,255,0)] via-transparent via-30.95% bg-lupine-600 hover:bg-lupine-500 text-white hover:text-white";
  const defaultStyles = "border-lupine-600/20 hover:border-lupine-600/40 bg-white hover:bg-lupine-400/05 text-lupine-950";
  const widthStyles = width === "full" ? "w-full" : "w-auto";

  return (
    <Link
      href={href}
      className={cn(
        "flex relative justify-center shadow items-center rounded-xl border px-4 py-3 text-sm font-[570] transition-all",
        widthStyles,
      {
        [primaryStyles]: variant === "primary",
        [defaultStyles]: variant === "default",
      })}
    >
      <span>{CTA}</span>
    </Link>
  );
};
import React, { useState } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { WolfiaLogo } from '../logo/WolfiaLogo';
import { redirectToLoginOrDashboard } from "@/utils/url";

const CTA_TEXT_LOGIN = "Log in";

export const MarketingNav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <div className={`py-4 md:py-6 sticky top-0 inset-x-0 backdrop-blur-xl z-10 bg-white/85 font-general-sans`}>
      <nav className="max-w-5xl px-4 mx-auto flex justify-between items-center">
        <WolfiaLogo width={32} height={32} />
        <div className="hidden md:block">
          <NavLinks />
        </div>
        <button
          type="button"
          onClick={() => setIsMenuOpen((open) => !open)}
          aria-label="toggle menu"
          className="md:hidden text-2xl text-black p-2 -mr-2"
        >
          <Bars3Icon width="1em" height="1em" />
        </button>
        <div
          className={`${
            isMenuOpen ? 'translate-x-0' : '-translate-y-[200%]'
          } transition-transform top-0 right-0 left-0 fixed bg-white p-4 flex flex-col items-end gap-8 drop-shadow-lg md:hidden`}
        >
          <button
            type="button"
            onClick={() => setIsMenuOpen((open) => !open)}
            aria-label="close menu"
            className="md:hidden text-2xl text-black p-2 -mr-2"
          >
            <XMarkIcon width="1em" height="1em" />
          </button>
          <NavLinks />
        </div>
      </nav>
    </div>
  );
};

const NavLinks = () => {
  return (
    <ul className="flex flex-col md:flex-row gap-6">
      <NavLink href="/blog">Blog</NavLink>
      <NavLink href="/case-studies">Case studies</NavLink>
      <NavLink href="/pricing">Pricing</NavLink>
      <NavLink href="/about">About</NavLink>
      <li>
        <Link
          href={redirectToLoginOrDashboard()}
          className="font-general-sans text-center text-base text-black py-3 px-4 md:-my-3 rounded-full border block border-gray-200 bg-gray-100 hover:border-gray-500 hover:bg-gray-200 hover:text-gray-700 transition-colors outline-none focus-visible:outline-black outline-offset-2"
        >
          {CTA_TEXT_LOGIN}
        </Link>
      </li>
    </ul>
  );
};

const NavLink = ({ href, children }: { href: string; children: React.ReactNode }) => {
  const router = useRouter();
  const isActive = router.pathname.startsWith(href);
  const activeClassName = isActive
    ? 'text-black before:left-3 before:right-3'
    : 'hover:text-black hover:before:left-3 hover:before:right-3 focus-visible:before:left-3 focus-visible:before:right-3';
  return (
    <li className="flex justify-end md:block">
      <Link
        href={href}
        className={`block w-max text-black focus-visible:text-black transition-colors p-3 md:-m-3 relative before:absolute before:h-[2px] before:left-1/2 before:right-1/2 before:bottom-1 before:rounded-full before:bg-black before:transition-all outline-none ${activeClassName}`}
      >
        <span className="font-general-sans md:text-base text-lg font-medium">
          {children}
        </span>
      </Link>
    </li>
  );
};

const FOOTER_NAV_SECTIONS = [
  {
    heading: "Explore",
    links: [
      { href: "/", label: "Home" },
      { href: "/about", label: "About" },
    ],
  },
  {
    heading: "Resources",
    links: [
      { href: "/blog", label: "Blog" },
      { href: "/case-studies", label: "Case studies" },
      { href: "/terms", label: "Terms of service" },
      { href: "/privacy", label: "Privacy policy" },
    ],
  },
  {
    heading: "Follow us",
    links: [
      { href: "https://twitter.com/wolfia_app", label: "Twitter", external: true },
      { href: "https://www.linkedin.com/company/wolfia-app", label: "LinkedIn", external: true },
    ],
  },
];

export const MarketingFooter = () => {
  return (
    <footer className='bg-white font-general-sans'>
      <div className="border-b border-gray-200" />
      <div className="max-w-5xl py-10 px-4 md:py-20 mx-auto flex flex-col md:flex-row md:justify-between items-center md:items-start gap-8">
        <p className="flex items-center">
          <span className="font-general-sans text-black text-sm">
            © {new Date().getFullYear()} Wolfia. All rights reserved.
          </span>
        </p>
        <ul className="flex flex-row w-full md:w-auto justify-around md:gap-20">
          {FOOTER_NAV_SECTIONS.map((section) => (
            <li key={section.heading}>
              <h3 className="font-general-sans text-sm font-medium mb-6 text-black text-center md:text-left">
                {section.heading}
              </h3>
              <ul className="flex flex-col gap-6 items-center md:items-start">
                {section.links.map((link) => (
                  <FooterNavLink key={link.href} href={link.href} target={link.external ? "_blank" : undefined}>
                    {link.label}
                  </FooterNavLink>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  );
};

const FooterNavLink = ({
  href,
  children,
  target,
}: {
  href: string;
  children: React.ReactNode;
  target?: '_blank';
}) => {
  return (
    <li className="w-full">
      <Link
        href={href}
        className="block font-general-sans text-sm text-black font-medium opacity-60 hover:opacity-100 transition-opacity p-3 -m-3 rounded focus-visible:outline-black outline-2 outline-offset-2 hover:underline"
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
      >
        {children}
      </Link>
    </li>
  );
};